import { DiscoRenderFunction, DiscoSdk } from 'types';

export default function createSdk(render: DiscoRenderFunction): DiscoSdk {
  return {
    render,
    // the implementation of this function is added by addStylesToDom, which is
    // called by webpack loaders to install styles.  addStylesToDom isn't processed
    // by webpack - it's just stringified and called by the webpack runtime.  As such,
    // addStylesToDom cannot reference any outside functions and cannot include anything
    // in a closure.  Since addStylesToDom has to be called by application code as well
    // (see below and the injectInstalledStyles util), addStylesToDom adds its injection
    // handler to the global disco object so that it can be reused
    _addStyleElement: null,
    // contains all known elements into which Disco has been injected
    _injectionPoints: null,
    // for each style that webpack has to install, it calls addStylesToDom.  this call
    // happens exactly once for each file.  addStylesToDom adds the installed style
    // element to the _installedStyles array so that when widgets are dynamically
    // created, all known styles can be injected.  If this isn't done, additional
    // instances of the widget will be missing critical style files.
    _installedStyles: null,
  };
}

export function addInjectionPoints(
  sdk: DiscoSdk,
  slots: Element[] | undefined,
) {
  if (sdk === undefined || slots === undefined) {
    return sdk;
  }

  sdk._injectionPoints = (sdk._injectionPoints ?? [])
    .concat(slots)
    // filter removes dupes if slot was already in _injectionPoints
    .filter((injectionPoint, index, ary) => {
      return ary.indexOf(injectionPoint) === index;
    });
}
