import qs from 'query-string';
import { APP_HOSTNAMES } from 'utils/constants';

export function isRunningOnDiscoPage(): boolean {
  return (
    APP_HOSTNAMES.find((hostname) => hostname === window.location.hostname) !==
    undefined
  );
}

export function isRunningInIFrame() {
  return window.location !== window.parent.location;
}

/*
 * see https://amp.dev/documentation/components/amp-iframe/#src
 *
 *    " the #amp=1 fragment is added to the URL to allow source documents to
 *    know that they are embedded in the AMP context"
 */
export function isRunningInAmpIFrame() {
  const parsedHash = qs.parse(window.location.hash);
  return parsedHash.amp === '1';
}
