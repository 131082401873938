function storageAvailable(type: 'localStorage' | 'sessionStorage') {
  try {
    const storage = window[type];
    const test = 'test';
    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export default storageAvailable;
