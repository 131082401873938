import layoutVariables from 'styles/layout.module.scss';

// ----- player
export const PLAYER_DEFAULT_VOLUME = 0.5;
export const PLAYER_MAX_VOLUME = 1;

// ----- waveform
export const WAVEFORM_DEFAULT_COLOR = '#ffffffee';
export const WAVEFORM_BAR_WIDTH = 2;
export const WAVEFORM_BAR_SPACING = 2;
export const WAVEFORM_SAMPLE_PLAYBACK_RATE = 1000 / 22;
export const WAVEFORM_PLAYER_DEFAULT_DURATION_SEC = 60;
export const WAVEFORM_PLAYER_TIMEUPDATE_FREQ_MILLIS = 200;

// ----- widget injection
export const WIDGET_SLOT_SELECTOR = process.env.REACT_APP_WIDGET_SLOT_SELECTOR;
export const WIDGET_VISIBILITY_THRESHOLD = 0.5;
export const WIDGET_VISIBILITY_TIMEOUT = 1000;
export const WIDGET_THUMBNAIL_VARIANT_ID = 'DV_cko4sosvd00010uovnm8jz95a';
export const WIDGET_CAROUSEL_VARIANT_ID = 'DV_cko4spbf500020uovi0bu245e';
export const WIDGET_THUMBNAIL_VARIANT_NAME = 'thumbnail';
export const WIDGET_CAROUSEL_VARIANT_NAME = 'carousel';
export const WIDGET_VARIANT_ALL_IDS: string[] = [
  WIDGET_THUMBNAIL_VARIANT_ID,
  WIDGET_CAROUSEL_VARIANT_ID,
];
export const WIDGET_VARIANT_ALL_NAME: string[] = [
  WIDGET_THUMBNAIL_VARIANT_NAME,
  WIDGET_CAROUSEL_VARIANT_NAME,
];
export const DEFAULT_WIDGET_TITLE = 'Related podcasts';

// in the dom, these will be written as <element data-variant="thumbnail" />
export const WIDGET_VARIANT_DATA_ATTR_NAME = 'variant';

// ----- responsiveness
export const WIDGET_MOBILE_BREAKPOINT = parseInt(
  layoutVariables.widgetMobileBreakpoint,
  10,
);
export const THREE_BY_ONE_STACKED_MOBILE_BREAKPOINT = 500;
export const TEXT_ONLY_WIDGET_MOBILE_BREAKPOINT = 650;

// ----- api
export const API_BASEURL = process.env.REACT_APP_API_BASEURL;

export const API_BEACON_RECEIVE_ENDPOINT_V1 = '/api/v1/b/receive';
export const API_BEACON_RECEIVE_URL = `${API_BASEURL}${API_BEACON_RECEIVE_ENDPOINT_V1}`;

export const API_WIDGET_REQUEST_ENDPOINT_V1 = '/api/v1/widget/widget-request';
export const API_WIDGET_REQUEST_URL = `${API_BASEURL}${API_WIDGET_REQUEST_ENDPOINT_V1}`;
export const API_CAMPAIGN_REQUEST_ENDPOINT_V1 =
  '/api/v1/widget/campaign-preview-request';
export const API_CAMPAIGN_REQUEST_URL = `${API_BASEURL}${API_CAMPAIGN_REQUEST_ENDPOINT_V1}`;
export const API_FLAG_SEGMENT_ENDPOINT = '/api/v1/widget/segment-flag';
export const API_FLAG_SEGMENT_REQUEST_URL = `${API_BASEURL}${API_FLAG_SEGMENT_ENDPOINT}`;

// ----- deployment
export const DEPLOY_ENV = process.env.REACT_APP_DEPLOY_ENV;
export const BASE_URL = process.env.REACT_APP_BASE_URL?.endsWith('/')
  ? process.env.REACT_APP_BASE_URL
  : `${process.env.REACT_APP_BASE_URL}/`;

// used to know if the app is running on a page from this project or a publisher
// page, for example
export const APP_HOSTNAMES: string[] = [
  // always support running the app on localhost
  'localhost',

  // when app is deployed to prod, it will have a public url.  if the app is running
  // on a page that has the same hostname as the public url, we can assume it's a
  // Disco page
  process.env.NODE_ENV === 'production'
    ? new URL(process.env.PUBLIC_URL).hostname
    : undefined,

  // additional hostnames - for example ngrok or localtunnel, which can be added
  // to a local .env file
  ...(process.env.REACT_APP_HOSTNAMES?.split(',').map((hn) => hn.trim()) ?? []),
].filter(Boolean) as string[];

// ----- utility
export const SECONDS_IN_HOUR = 3600;

// ----- tracking
export const MEDIA_PLAYBACK_HEARTBEAT_MILLIS = 15000;

// internal links
export const PRIVACY_POLICY_URL =
  'https://www.headliner.app/docs/privacy-policy.html';
export const TERMS_OF_SERVICE_URL =
  'https://www.headliner.app/docs/terms-of-service.html';
export const HEADLINER_APP_URL = 'https://www.headliner.app';
export const HEADLINER_DISCO_URL =
  'https://www.headliner.app/publisher-widgets/';
