import { lazy, StrictMode, Suspense } from 'react';
import { render } from 'react-dom';
import createSdk, { addInjectionPoints } from 'apps/widget/sdk';
import { recordSessionId } from 'state/session/session';
import { WidgetConfiguration } from 'types';
import {
  getInjectionPoints,
  getWidgetConfig,
  injectInstalledStyles,
  injectTextDirection,
} from 'utils/injection';

// NB: It's important this this is a lazy import.  If not lazy, all of the styles
// associated with the component tree will be injected at import time, which is
// before the shadow dom gets created below.  Since styles have to be injected into
// the shadow dom (see webpack config), we have to ensure shadow dom exists before
// importing any styles
const App = lazy(() => import('./App'));

async function injectWidgets(
  elementOrSelector?: string | HTMLElement,
  config?: Partial<WidgetConfiguration>,
) {
  const slots = getInjectionPoints(elementOrSelector);
  addInjectionPoints(window.disco, slots);

  if (slots && slots.length > 0) {
    // slots found.  begin injecting widget.
    // webpack will inject styles into the shadow-dom and we want to inject
    // styles before injecting the widget in order to prevent FOUC.
    // first, create shadow doms
    const slotsAndShadows = Array.from(slots).map((slot) => {
      if (slot.shadowRoot) {
        return [slot, slot.shadowRoot] as const;
      }
      return [slot, slot.attachShadow({ mode: 'open' })] as const;
    });

    // styles have been added so widgets can be injected
    for (const [slot, shadow] of slotsAndShadows) {
      // TODO: consider different logic for URL config parameters.  those can be
      // read once and used outside of this loop since they are not expected to
      // change.  all widgets on the page can share the same query param config
      const { direction, title, url, variantId, widgetId } =
        await getWidgetConfig(slot, config);

      injectTextDirection(slot, direction);

      if (widgetId && shadow.querySelector('.root') === null) {
        // `render` will replace all children of the container, which would
        // drop the styles we just added (link tags).  create a root inside
        // of the shadow-dom which react can have full control over
        const root = document.createElement('div');
        root.className = 'root';
        root.style.position = 'relative';
        shadow.appendChild(root);

        injectInstalledStyles(slot);
        const sessionId = recordSessionId(widgetId, url);

        render(
          <StrictMode>
            <Suspense fallback={null}>
              <App
                reactRoot={root}
                {...{ sessionId, title, url, widgetId, variantId }}
              />
            </Suspense>
          </StrictMode>,
          root,
        );
      }
    }
  }
}

window.disco = createSdk(injectWidgets);

async function initialize() {
  injectWidgets();
}

initialize();
